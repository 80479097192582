<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo abastecimento</span>
    </button>
    <button
      style="margin-left: 10px"
      class="button field is-info is-light"
      @click="isImportFuelModalActive = true"
    >
      <span>Importar</span>
    </button>
    <b-table
      :data="allFuelSupplies"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ $moment(props.row.date).format('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column
        field="vehicle"
        label="Veículo"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.vehicle.plate }}
      </b-table-column>

      <b-table-column
        field="price"
        label="Valor"
        sortable
        :searchable="true"
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <b-table-column
        field="quantity"
        label="Qtd."
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.quantity }}</b-table-column
      >

      <b-table-column
        field="type"
        label="Tipo"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.fuel_type }}</b-table-column
      >

      <b-table-column
        field="odometer"
        label="Odômetro"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.odometer }}</b-table-column
      >

      <b-table-column
        field="pending"
        label="Status"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <b-taglist style="margin-top: 3px; font-weight: bold">
          <b-tag :type="props.row.pending ? 'is-warning' : 'is-success'">{{
            props.row.pending ? 'PENDENTE' : 'APROVADO'
          }}</b-tag>
        </b-taglist></b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          v-if="props.row.pending"
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            approvalFuelSupply()
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isImportFuelModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <input
              id="input-import-file"
              type="file"
              @change="onChange"
              style="display: none"
            />

            <b-field v-for="(item, index) of allTempFuelSupplies" :key="index">
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">
                    Placa: {{ item.plate }} | Data:
                    {{ $moment(item.date).format('DD/MM/YYYY') }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    Quantidade: {{ item.quantity }}
                    <br />
                    Tipo: {{ item.fuel_type.toString().toUpperCase() }}
                    <br />
                    Valor: R${{ parseFloat(item.value).toFixed(2) }}
                    <br />
                    Odômetro: {{ item.odometer }}
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removeFuelSupply(index)" class="card-footer-item"
                    >Remover</a
                  >
                </footer>
              </b-collapse>
            </b-field>
            <b-button
              v-if="!allTempFuelSupplies.length"
              type="button field is-info"
              class="mt-10"
              @click="initImportFile"
              >Importar planilha</b-button
            >
            <b-button
              v-if="allTempFuelSupplies.length > 0"
              type="button field is-info"
              class="mt-10"
              @click="saveImportData"
              >Salvar importação</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Data *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.date"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Veículo *">
              <b-select
                placeholder="Selecione um veículo"
                required
                expanded
                v-model="model.vehicle_id"
              >
                <option
                  v-for="(item, index) in allVehicles"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.plate }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Tipo de combustível *">
              <b-select
                placeholder="Selecione um combustível"
                required
                expanded
                v-model="model.fuel_type"
              >
                <option
                  v-for="(item, index) in [
                    'Gasolina comum',
                    'Gasolina aditivada',
                    'GNV',
                    'Álcool',
                    'Diesel',
                  ]"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Quantidade *">
              <b-input v-model="model.quantity" required></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-field label="Odômetro *">
              <b-input
                required
                type="number"
                v-model="model.odometer"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/fuel-supplies/store/service'
import FuelSupply from '../models/fuel-supply'
import XLSX from 'xlsx'

export default {
  name: 'FuelSupplies',
  data() {
    return {
      model: FuelSupply.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
      isImportFuelModalActive: false,
      allTempFuelSupplies: [],
      file: null,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('fuelSupplies', ['allFuelSupplies']),
    ...mapState('vehicles', ['allVehicles']),
  },
  methods: {
    ...mapActions('fuelSupplies', ['getAllFuelSupplies']),
    ...mapActions('vehicles', ['getAllVehicles']),
    async init() {
      await this.getAllFuelSupplies()
      await this.getAllVehicles()
    },
    async saveImportData() {
      try {
        await service.saveImportFuelSupply({ data: this.allTempFuelSupplies })

        await this.$success('Importação de abastecimento')
        await location.reload(true)

        this.model = FuelSupply.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    approvalFuelSupply() {
      this.model.pending = false

      this.save()
    },
    async save() {
      const fuelSupply = { ...this.model }

      fuelSupply.date = this.$moment(fuelSupply.date).format('YYYY-MM-DD')
      fuelSupply.value = fuelSupply.value.toString().replace('R$ ', '')

      if (fuelSupply.vehicle) delete fuelSupply.vehicle

      try {
        const save = fuelSupply.id
          ? await service.updateFuelSupply(fuelSupply)
          : await service.saveFuelSupply(fuelSupply)

        await this.$success('Abastecimento')
        await location.reload(true)

        this.model = FuelSupply.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o abastecimento?')

        if (result.isConfirmed) {
          await service.deleteFuelSupply(id)

          await this.$delete('Abastecimento')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      item.date = new Date(this.$moment(item.date))

      this.model = { ...item }
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
    onChange(event) {
      this.file = event.target.files[0]
      console.log(this.file)

      this.normalizeXlsx()
    },
    initImportFile() {
      document.getElementById('input-import-file').click()
    },
    removeFuelSupply(index) {
      this.allTempFuelSupplies.splice(index, 1)
    },
    normalizeXlsx() {
      let binary = ''

      const arr = []
      let env = null
      env = this
      this.loading = true

      const reader = new FileReader()

      reader.onload = function () {
        const bytes = new Uint8Array(reader.result)
        const length = bytes.byteLength
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }

        const result = XLSX.read(binary, {
          type: 'binary',
        })

        const outdata = XLSX.utils.sheet_to_json(
          result.Sheets[result.SheetNames[0]]
        )

        const ws = result.Sheets[result.SheetNames[0]]

        const testData = XLSX.utils.sheet_to_row_object_array(ws, {
          date_format: 'dd/mm/yyyy',
        })

        outdata.forEach((x) => {
          if (x['PLACA']) {
            const date = new Date()
            const dateNotFormat = x['DATA'].toString().split('/')
            date.setDate(dateNotFormat[0])
            date.setMonth(parseInt(dateNotFormat[1]) - 1)
            date.setFullYear(dateNotFormat[2])

            arr.push({
              plate: x['PLACA'],
              date: env.$moment(date).format('YYYY-MM-DD'),
              value: x['VALOR TOTAL'].replace(',', '.'),
              odometer: parseInt(x['ODÔMETRO']),
              fuel_type: x['PRODUTO'].toString().toUpperCase(),
              quantity: parseFloat(
                x['QUANTIDADE'].toString().replace(',', '.')
              ).toFixed(2),
            })
          }
        })

        env.allTempFuelSupplies = arr
        env.loading = false

        console.log(env.allTempFuelSupplies)

        return arr
      }

      setTimeout(() => {
        reader.readAsArrayBuffer(this.file)
      }, 100)
    },
  },
}
</script>

<style scoped></style>
